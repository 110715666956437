<template>
  <Layout>
    <div class="row">
      <div class="col-lg-12">
        <div class="card border border-radius-12">
         
          <div class="card-body table">

            <div class="row justify-content-between">
              <div class="col-lg-6"> 
                <div class="d-flex align-items-sm-center align-items-start gap-lg-3 gap-2 flex-column flex-sm-row"> 

                
                <PageHeader :title="title" :items="items" class="poppins fw-normal ps-3" /> 
                  <searchInput @getSearched="handleSearch" class="w-sm-100"/> 
                </div>
              </div>
          
             
                <!-- <div :class="{'buttons_wrapper': true,  'border-orange rounded-pill p1': multipuleIds?.length >= 1}">
                 
                <button v-if="multipuleIds?.length >= 1" @click="deleteBulk" class="
                    btn
                    border-0
                    px-5
                    fw-bold
                    text-white
                    btn btn-primary
                    waves-effect waves-light
                    w-md
                    primary-button
                    rounded-pill
                        ">
                  Delete All
                </button>
              </div> -->
            
            <div class ="col-lg-6"> 
              <div class="d-flex justify-content-end gap-2">
              <div class="buttons_wrapper p1 rounded-pill border-orange fit-content"> <router-link to="/business/add" class="
                rounded-pill btn border-0 px-md-4 px-xl-5 fw-bold text-white btn btn-primary waves-effect waves-light w-md primary-button">
                  Add New   
                </router-link></div>
                <div class="buttons_wrapper p1 rounded-pill border-dark fit-content"> 
                <router-link to="/csvupload" class="
                btn waves-effect waves-light w-md bg-black  px-md-3 text-nowrap px-xl-5 text-white fw-bold rounded-pill
                                                                ">
                  Bulk Upload
                </router-link>

              </div>
            </div>
            </div>
            </div>
            <GeneralTable :isProcessing="processing" 
            :items="items"  :fields="fields"
            @editItem="listingUpdate"
            @deleteItem="deleteRestaurant" >
          <template #custom-option>
            
          </template>
          </GeneralTable>
          <Pagination
          @paginator="allBusiness"
          :pagination="paginatelinks"
          :showing="paginate"
        />
          
           
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>
<script>
import Layout from "../../../layouts/static/main";
import PageHeader from "@/components/static/page-header";
import appConfig from "@/app.config";
import Swal from "sweetalert2";
import $ from "jquery";
import { slotFlagsText } from "@vue/shared";
import GeneralTable from "../../../../components/MagicTable/GeneralTable.vue";
import searchInput from "../../../../components/general/searchInput.vue";
import Pagination from "../../../../components/general/pagination.vue";

export default {
  page: {
    title: "Restaurants",
    meta: [
      {
        name: "add-user",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader,
    GeneralTable,
    searchInput,
    Pagination,

  },
  data() {
    return {
      fields: [
        { key: 'select', label: 'Select', sortable: false },  
        { key: 'id'},
        { key: 'avatar_with_title', label: 'Resturant Name'},
        // { key: 'email',tdClass:''},
        { key: 'mobile', label: 'Phone',},
        { key: 'social_links', label: 'Social Links'},
        { key: 'address',tdClass:'text-lg-wrap ', label: "Location"},        
        { key: 'action',tdClass:''},
      ],
      mergListingbtn: false,
      selectedBusiness: [],
      selectAll: false,
      processing: false,
      status: null,
      loading: true,
      listing: null,
      title: "Restaurants",
      links: [],
      multipuleIds: [],
      paginate: {},
      paginatelinks:[],
      items: [
        {
          text: "Omaha",
        },
        {
          text: "Business Listing",
          active: true,
        },
      ],
    };
  },
  mounted() {
    this.allBusiness({});
    this.processing = true;
  },
  computed: {
    paginatedItems() {
      const start = (this.currentPage - 1) * this.itemsPerPage;
      return this.items.slice(start, start + this.itemsPerPage);
    }
  },
  methods: {
    deleteRestaurant(id) {
      let apiUrl = `delete-business-listing/`;
           let confirmationMessage = "Restaurant deleted successfully."
           this.processing = true;
           this.deleteIt(apiUrl, id,confirmationMessage)
           .then(() => {
            this.processing = false;
            this.allBusiness({});
            }).catch(error => {
                console.error('Error deleting job:', error);
                this.processing = false;
            });
      console.log('Received ID from child:', id);
    },
    handleSearch(searchValue) {
      this.allBusiness({ searchValue });
    
  },
    randerData(response) {
       this.paginate = {
        first_page: response.data.data.first_page_url,
        last_page: response.data.data.last_page_url,
        links: response.data.data.links,
        from: response.data.data.from,
        to: response.data.data.to,
        total: response.data.data.total,
       }
        
       this.paginatelinks=this.paginate.links.map(item=>({
              url: item.url,
              label: item.label,
              active: item.active,
            }));

      let listing = Array.isArray(response.data.data.data) && response.data.data.data.length > 0
      ? response.data.data.data
      :[];
      this.items = listing.map(list =>({
        id: list.id,
        avatar: list.banner_url,
        name: list.title || "N/A",
        oflMember: list.is_member,
        email: list.email || "N/A",
        mobile: list.mobile || "N/A",
        address: list?.address?.address ||  "N/A",
        social_links: list.social_media,
      }));
      this.processing = false;
       console.log(response,"resturant listing")
      this.loading = false;
    },
    allBusiness({ url = null, searchValue = null } = {}) {
      this.processing = true;
      let endPoint = "all-listing";
      endPoint = url ? url : endPoint;
      this.$axios
    .get(endPoint, { params: {search: searchValue } })
    .then((response) => {
      console.log('Business Data:', response.data);
      setTimeout(() => this.randerData(response), 1500);
    })
    .catch((error) => {
      console.log(error.response);
      this.processing = false;
    });
},

    getSafe(fn, defaultValue = "N/A") {
      try {
        return fn();
      } catch (error) {
        return defaultValue;
      }
    },
    async listingDeleted(id) {
      try {
        const {
          data: { status },
        } = await this.$axios.delete("delete-business-listing/" + id);
        this.responselistingDelete = status;
      } catch (error) {
        console.log(error);
      }
    },
    deleteListing(id) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete it!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          await this.listingDeleted(id);
          let status = this.responselistingDelete;
          if (status == true) {
            Swal.fire("Deleted!", "Your file has been deleted.", "success");
            this.allBusiness();
          }
        }
      });
    },
    listingUpdate: function (id) {
      this.$router.push({
        name: "update-list",
        params: { id: id },
      });
    },
    statusChange(id, status) {
      let data = {
        status: status,
        vendor_listing_id: id,
      };
      this.swalAlert(data, status);


    },

    swalAlert(data, status) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Change it!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          await this.apiForStatusChange(data, status);
          Swal.fire("Updated!", "Status has been Changed.", "success");

        }
      });
    },

    apiForStatusChange(data, status) {
      if (status == "rejected") {
        this.rejectListing(data);
      } else {
        this.$axios
          .post("vendor-listing-status", data)
          .then((response) => {
            this.allBusiness();
            this.triggerSwal(response.data.data, "success");
          })
          .catch((error) => {
            console.log(error.response);
          });
      }
    },
    sponsership(index) {
      let singleRecord = this.listing[index];
      this.$axios
        .post("change-sponsored-ship", {
          id: singleRecord.id,
          sponsorship: singleRecord.sponsorship,
        })
        .then((response) => {
          console.log(response.data);
          this.triggerSwal(response.data.message, "success");
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    rejectListing(data) {
      Swal.fire({
        title: "Are you sure?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, Change it!",
      }).then((result) => {
        if (result.isConfirmed) {
          this.$axios
            .post("vendor-listing-status", data)
            .then((response) => {
              this.allBusiness();
            })
            .catch((error) => {
              console.log(error.response);
            });
          Swal.fire("Updated!", "Status has been Changed.", "success");
          this.allBusiness();
        }
      });
    },
    selectAllRecord() {
      if (this.selectAll) {
        let ids = [];
        this.listing.forEach((element) => {
          ids.push(element.id);
        });
        this.multipuleIds = [...new Set(ids)];
        console.log(this.multipuleIds);
      } else {
        this.multipuleIds = [];

        console.log(this.multipuleIds);
      }
    },
    deleteBulk() {
      this.$axios
        .post("delete-bulk", { ids: this.multipuleIds })
        .then((response) => {
          this.allBusiness();
          this.triggerSwal(response.data.message, "success");
          this.selectAll = false;
          this.multipuleIds = [];
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    approveBulk() {
      this.$axios
        .post("approve-bulk", { ids: this.multipuleIds })
        .then((response) => {
          this.allBusiness();
          this.triggerSwal(response.data.message, "success");
          this.selectAll = false;
          this.multipuleIds = [];
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
    mergList(event, businessName) {
      if (event.target.checked) {
        if (this.selectedBusiness?.length == 0) {
          this.selectedBusiness.push(businessName);
          // this.mergListingbtn=true;
        }
        else {
          let status = this.selectedBusiness.find(e => e.title == businessName.title);
          if (status) {
            this.selectedBusiness.push(businessName);
            this.mergListingbtn = true;
          }
          else {
            this.mergListingbtn = false;
          }
        }
      } else {
        this.selectedBusiness.splice(businessName, 1);
        this.selectedBusiness?.length <= 1 ? this.mergListingbtn = false : this.mergListingbtn = true
      }
    },
    listingMerge() {
      this.$axios.post('/merge-listings', this.selectedBusiness).then(response => {
        this.triggerSwal(response.data.message, "success");
        this.mergListingbtn = false;
        this.allBusiness();
      }).catch(error => {
        console.log(error.response);
      })
    }
  },
};
</script>